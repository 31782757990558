import React from 'react'
import ReactPlayer from 'react-player'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import axios from '../../api/axios'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'

function Videos({ videosData, showDelete = false }) {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()

  const deleteVideoMutation = useMutation(
    async (videoId) => {
      if (!videoId) {
        return console.error('No video id provided')
      }
      return await axiosPrivate.delete(`/videos/${videoId}`, { videoId })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['getVideosBasedOnFolderId'],
        })
        toast.success('Deletion successful...')
      },
      onError: (err) => {
        console.error(
          'err: at delete video mutation 🛑',
          JSON.stringify(err, null, 2),
        )
      },
    },
  )

  const handleDelete = (videoId) => {
    deleteVideoMutation.mutate(videoId)
  }

  const handleVideoDownload = async (videoKey, fileName) => {
    //Using axios get the signed url
    const url = await axios.post(`/videos/downloadLink`, {
      key: videoKey,
      fileName: fileName,
    })
    //Using the signed url download the video
    const link = document.createElement('a')
    link.href = url.data.url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-8'>
      {videosData.length > 0
        ? videosData.map((video) => {
            return (
              //Add video on top and bottom of the div with name and player controls
              <div
                key={video._id}
                className='block  aspect-video rounded border border-gray-100/20 px-2 py-2 space-y-8'
              >
                <ReactPlayer
                  url={video.signedUrl}
                  controls={true}
                  height='100%'
                  width='100%'
                />
                <div className='flex justify-between'>
                  <h3 className='mr-2'>{video.fileName}</h3>
                  <div className='space-x-3'>
                    {showDelete ? (
                      <button
                        className='rounded border border-gray-100/20 bg-red-800 hover:bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
                        onClick={() => {
                          handleDelete(video._id)
                        }}
                      >
                        Delete
                      </button>
                    ) : null}
                    <button
                      onClick={() => {
                        handleVideoDownload(video.key, video.fileName)
                      }}
                      className='rounded bg-green-600 px-2 py-1 font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 text-sm disabled:opacity-50 disabled:cursor-not-allowed'
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            )
          })
        : null}
    </div>
  )
}

export default Videos

/* { {
   videosData.length > 0 ?
     videosData.map((video) => {
       return (
         <>
           <div key={video._id} className="border border-gray-600 rounded-md p-2 flex justify-between">
             <h3>{video.fileName}</h3>
             <div className='space-x-4'>
               {showDelete ? <button
                 className="rounded border border-gray-100/20 bg-darkAscent-100 hover:bg-red-600 px-2 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                 onClick={() => {
                   handleDelete(video._id)
                 }}
               >Delete</button> : null}
               <Link to={`?showModal=true&videoUrl=${video.signedUrl}`}
                 className="rounded border border-gray-100/20 bg-green-700 hover:bg-green-600 px-2 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                 onClick={() => {
                   console.log("Hook up play")
                 }}
               >Play</Link>
             </div>
           </div>
           <VideoPlayer />
         </>
       )
     })
     : <p>No videos found</p>
 } }*/
