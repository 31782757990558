import React from 'react'
import { useQuery } from 'react-query'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import DirectoryTree from './DirectoryTree'

function FolderTreeMain({ selectedImages, setOpenMove, currentFolderId }) {
  const axiosPrivate = useAxiosPrivate()
  const [folderTree, setFolderTree] = React.useState(null)

  //Get the clients list to get root folder id to fetch the tree
  const getClients = async () => {
    const { data } = await axiosPrivate.get(`/clients`)
    return data
  }

  const { data: clients, isLoading: clientsLoading, error: clientsError } = useQuery(['getClients'], getClients, {
    enabled: true,
    refetchOnWindowFocus: false,
  })

  const selectClient = async (clientId) => {
    const { data } = await axiosPrivate.get(`/folders/tree/${clientId}`)
    const arrayData = Object.values(data)
    setFolderTree(arrayData)
  }

  return (
    <div className='max-h-96 overflow-scroll p-4 bg-slate-200 my-8'>
      <h1 className='text-slate-800'>Folder Tree</h1>

      {/* Clients list */}
      {clientsLoading && <p>Loading...</p>}
      {clientsError && <p>There was an error while getting the clients list. Please try again later</p>}
      <div className="grid grid-cols-3 gap-4">
        {clients && clients?.clients?.length > 0 && clients?.clients.map((client, index) => (
          <div
            key={`client.name-${index}`}
            className="relative flex items-center rounded-lg border border-gray-300/20 bg-gray-800 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-100 hover:bg-gray-700"
            onClick={() => selectClient(client._id)}
          >
            <div className="min-w-0 flex-1">
              <div className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-50/80">{client.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {folderTree?.length > 0 ? folderTree.map((rootDirectory, index) => (
        <div className='mt-10' key={index}>
          <h2 className="text-lg font-semibold text-black">Directory {index + 1}</h2>
          {selectedImages.length > 0 ? <DirectoryTree directory={rootDirectory} isRoot={true} selectedImages={selectedImages} setFolderTree={setFolderTree} setOpenMove={setOpenMove} currentFolderId={currentFolderId} /> : null}
        </div>
      )) : null}

    </div>
  )
}

export default FolderTreeMain