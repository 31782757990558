import React from 'react'
import { Outlet, useLocation } from "react-router-dom"
import Login from "./Login"

const Layout = () => {
  const location = useLocation()

  return (
    <main className="flex flex-1 bg-gray-900 min-h-screen text-gray-50">
      {(location?.pathname === '/') && <Login />}
      <Outlet />
    </main>
  )
}

export default Layout