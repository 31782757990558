import React from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function DirectoryTree({ directory, isRoot = false, selectedImages, setFolderTree, setOpenMove, currentFolderId }) {
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient();

  const handleMove = async (folderId, previousFolderId) => {
    //Get the images ids from selectedImages
    const bothIds = []
    selectedImages.forEach(image => {
      bothIds.push(image.id)
      bothIds.push(image.originalImageId)
    })

    const payload = {
      folderId,
      imageIds: bothIds,
      previousFolderId
    }

    //Move images to the selected folder
    const data = await axiosPrivate.put(`/images/move`, payload)
    if (data.status === 200) {
      toast.success('Images moved successfully')
      queryClient.invalidateQueries({ queryKey: ['getImagesBasedOnFolderId', currentFolderId] })
      queryClient.invalidateQueries({ queryKey: ['getCurrentFolderDetails'] })
    }
    setFolderTree(false)
    setOpenMove(false)
  }
  return (
    <div>
      <div className={`pl-4 space-y-2 text-white ${!isRoot ? "border-l border-slate-800 pl-2 ml-2" : ""}`}>
        <div className={`relative ${!isRoot ? "pb-1" : ""}`}>
          {!isRoot && (
            <div className="absolute -left-2 top-1/2 w-4 h-0.5 bg-blue-500"></div>
          )}
          <div className='ml-4  border border-blue-900 bg-gray-900 inline-block p-2 text-white'>
            <div className='flex space-x-8'>
              <p>{directory.name}</p>
              <button className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" onClick={() => handleMove(directory.id, currentFolderId)}>Move here</button>
            </div>
          </div>
        </div>
        {Object.keys(directory.children).length > 0 && (
          <div className="ml-4">
            {Object.keys(directory.children).map((key) => (
              <DirectoryTree
                key={directory.children[key].id}
                directory={directory.children[key]}
                selectedImages={selectedImages}
                setFolderTree={setFolderTree}
                setOpenMove={setOpenMove}
                currentFolderId={currentFolderId}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default DirectoryTree