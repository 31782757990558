import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import axios from '../../api/axios'
import ErrorAlert from '../Helpers/ErrorAlert'
import Heading from '../Helpers/Heading'
import ImagesNew from '../ImagesComponents/ImagesNew'
import Videos from '../VideoComponents/Videos'
import SharedFolder from './SharedFolder'

function SharedSubfoldersView() {
  const params = useParams()
  const folderParams = params['*']
  const currentSlug = folderParams.split('/').pop()

  async function getCurrentFolderDetails() {
    const { data } = await axios.get(`shared/folders/slug/${currentSlug}`)
    return data
  }

  //Get current folder data
  const {
    data: folderData,
    isError: isErrorFolder,
    isLoading: isLoadingFolder,
    isSuccess: isSuccessFolder,
  } = useQuery(
    ['getCurrentFolderDetails', currentSlug],
    getCurrentFolderDetails,
  )

  const parentFolderId =
    !isErrorFolder && isSuccessFolder && folderData?.folder?._id
      ? folderData?.folder?._id
      : null

  //Get all child folders
  async function getCurrentFoldersChildren(parentFolderId) {
    const { data } = await axios.get(
      `shared/folders/childFolders/${parentFolderId}`,
    )
    return data
  }

  const {
    data: currentFoldersChildrenData,
    isError: isErrorCurrentFoldersChildrenData,
    isLoading: isLoadingCurrentFoldersChildrenData,
    isSuccess: isSuccessCurrentFoldersChildrenData,
  } = useQuery({
    queryKey: ['getCurrentFolderDetails', parentFolderId],
    queryFn: () => getCurrentFoldersChildren(parentFolderId),
    enabled: !!parentFolderId,
  })

  //Get the images based on folder id
  async function getImagesBasedOnFolderId(folderId) {
    if (!folderId) return
    const { data } = await axios.get(`shared/folders/images/${folderId}`)
    return data
  }

  const {
    data: imagesData,
    isError: isErrorImagesData,
    isLoading: isLoadingImagesData,
    isSuccess: isSuccessImagesData,
  } = useQuery({
    queryKey: ['getImagesBasedOnFolderId', parentFolderId],
    queryFn: () => getImagesBasedOnFolderId(parentFolderId),
    enabled: !!parentFolderId,
  })

  //Get the  videos based on folder id
  async function getVideosBasedOnFolderId(folderId) {
    if (!folderId) return
    const { data } = await axios.get(`/videos/${folderId}`)
    return data
  }

  const {
    data: videosData,
    isError: isErrorVideosData,
    isLoading: isLoadingVideosData,
    isSuccess: isSuccessVideosData,
  } = useQuery({
    queryKey: ['getVideosBasedOnFolderId', parentFolderId],
    queryFn: () => getVideosBasedOnFolderId(parentFolderId),
    enabled: !!parentFolderId,
    refetchOnWindowFocus: true,
  })

  //Get the pdfs based on folder id
  async function getPdfsBasedOnFolderId(folderId) {
    if (!folderId) return
    const { data } = await axios.get(`/pdfs/${folderId}`)
    return data
  }

  const {
    data: pdfsData,
    isError: isErrorPdfsData,
    isLoading: isLoadingPdfsData,
    isSuccess: isSuccessPdfsData,
  } = useQuery({
    queryKey: ['getPdfsBasedOnFolderId', parentFolderId],
    queryFn: () => getPdfsBasedOnFolderId(parentFolderId),
    enabled: !!parentFolderId,
    refetchOnWindowFocus: true,
  })

  return (
    <div className='my-4'>
      {isSuccessFolder && (
        <div className='my-4'>
          <h2 className='text-orange-400'>
            Parent folder:{' '}
            <span className='text-gray-300'>{folderData?.folder?.name}</span>
          </h2>
        </div>
      )}

      {isSuccessCurrentFoldersChildrenData &&
      currentFoldersChildrenData?.childFolders.length ? (
        <h3 className='text-lg leading-6 font-medium'>Folders</h3>
      ) : null}

      {isLoadingCurrentFoldersChildrenData && <p>Loading...</p>}

      {isErrorCurrentFoldersChildrenData && (
        <ErrorAlert message="There is an error ocurred while getting the client and it's folders." />
      )}

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4'>
        {isSuccessCurrentFoldersChildrenData &&
        currentFoldersChildrenData?.childFolders.length
          ? currentFoldersChildrenData.childFolders.map((folder) => (
              <span key={folder._id}>
                <SharedFolder folder={folder} />
              </span>
            ))
          : null}
      </div>

      {/* Images section */}
      {imagesData?.images.length > 0 && (
        <div className='text-gray-50'>
          <Heading heading='Images' />
        </div>
      )}
      <div className='overflow-hidden'>
        {isLoadingImagesData && <p>Loading...</p>}
        {isErrorImagesData && (
          <ErrorAlert message='There is an error ocurred while getting the images.' />
        )}
        {imagesData?.images.length > 0 && (
          <ImagesNew photos={imagesData.images} />
        )}
        {isSuccessImagesData && imagesData?.images.length === 0 && (
          <p>No Images available for this folder!</p>
        )}
      </div>

      {/* Videos section */}
      {videosData?.length > 0 && (
        <div className='text-gray-50 mt-4'>
          <Heading heading='Videos' />
        </div>
      )}

      <div className='overflow-hidden'>
        {isLoadingVideosData && <p>Loading...</p>}
        {isErrorVideosData && (
          <ErrorAlert message='There is an error ocurred while getting the videos.' />
        )}
        {videosData?.length > 0 && <Videos videosData={videosData} />}
        {isSuccessVideosData && videosData?.length === 0 && (
          <p>No Videos available for this folder!</p>
        )}
      </div>

      {/* PDFs section */}
      {pdfsData?.length > 0 && (
        <div className='text-gray-50 mt-4'>
          <Heading heading='PDFs' />
        </div>
      )}
      <div className='overflow-hidden'>
        {isLoadingPdfsData && <p>Loading...</p>}
        {isErrorPdfsData && (
          <ErrorAlert message='There is an error ocurred while getting the pdfs.' />
        )}
        {pdfsData?.length > 0 && (
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-4'>
            {pdfsData.map((pdf) => (
              <div
                key={pdf._id}
                className='border border-gray-100/20 rounded-md p-4 space-y-4'
              >
                <h1 className='text-gray-50 text-lg font-medium'>
                  {pdf.fileName}
                </h1>
                <div className='flex space-x-2'>
                  <a
                    className='block text-center rounded-md border-2 border-gray-100/20 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-50 hover:text-gray-900'
                    href={pdf.signedUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    Download
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
        {isSuccessPdfsData && pdfsData?.length === 0 && (
          <p>No PDFs available for this folder!</p>
        )}
      </div>
    </div>
  )
}

export default SharedSubfoldersView
