import React from 'react'
import { useQuery } from 'react-query'
import { useLocation } from 'react-router-dom'
import axios from '../../api/axios'
import Folder from '../Helpers/Folder'
import SharedFolder from './SharedFolder'

function SharedFoldersView() {
  const location = useLocation()
  //Get the last item from the pathname
  const folderSharableLink = location.pathname.split('/').pop()

  //fetch the folder id using react-query
  const { data, isLoading, isError, error } = useQuery(['getSharedFolder', folderSharableLink], async () => {
    const res = await axios.get(`shared/folders/${folderSharableLink}`)
    return res.data
  })

  return (
    <div className='my-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
      {isLoading && <div>Loading folder...</div>}
      {data && <SharedFolder folder={data.folder} />}
    </div>
  )
}

export default SharedFoldersView