import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import uuid from 'uuid-random';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function PDFUploader({ currentFolderId, currentPathForS3 }) {
  const [pdfFileName, setPdfFileName] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [isEventOngoing, SetIsEventOngoing] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient()

  const uploadPdf = async () => {
    try {
      if (!pdfFile) {
        toast.error('Please select a pdf file')
        return
      }
      SetIsEventOngoing(true);
      const randomId = uuid();
      //Form data preparation for upload
      const formData = new FormData();
      formData.append('file', pdfFile[0]);
      formData.append('folderId', currentFolderId);
      formData.append('currentPathForS3', currentPathForS3);
      formData.append('randomId', randomId);

      const pdfUploadResponse = await axiosPrivate.post(`/pdfupload/?path=${currentPathForS3}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });

      if (pdfUploadResponse.status === 200) {
        toast.success('PDF uploaded successfully')
      } else {
        toast.error('PDF upload failed')
      }
    } catch (error) {
      console.log('error uploading pdf:', error)
      console.log("Error uploading pdf 🛑", JSON.stringify(error, null, 2))
    } finally {
      setPdfFileName('')
      setPdfFile(null)
      SetIsEventOngoing(false)
      queryClient.invalidateQueries({ queryKey: ['getPdfsBasedOnFolderId'] });
    }
  }

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    //upload to server
    setPdfFileName(acceptedFiles[0].name)
    setPdfFile(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    maxFiles: 10,
    onDropRejected: () => {
      console.log('File rejected based on type or max files limit.');
      toast.error('No more than 10 PDF file(s) please.');
    },
    disabled: pdfFileName ? true : false
  })
  return (
    <div className='space-y-4'>
      <h1>Upload videos to this folder</h1>
      <div className='bg-slate-500 mt-4 min-h-[300px] flex items-center justify-center cursor-pointer' {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='text-white'>
          {
            isDragActive
              ? pdfFileName ? <p>Dropzone disabled</p> : <p>Drop the files here ...</p>
              : pdfFileName ? <p>Dropzone disabled</p> : <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>
      </div>
      {pdfFileName &&
        <div className='border border-black rounded-md p-2 flex justify-between'>
          <h2>{pdfFileName}</h2>
          <button onClick={() => setPdfFileName('')}>Delete</button>
        </div>}
      {/* Buttons */}
      <div className='flex md:justify-end space-x-3'>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => { setPdfFile(null); setPdfFileName('') }}
          disabled={isEventOngoing}
        >
          Reset
        </button>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={uploadPdf}
          disabled={isEventOngoing}
        >
          {isEventOngoing ? 'Uploading...' : 'Upload'}
        </button>
      </div>
    </div>
  )
}

export default PDFUploader