import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Clients from './components/Clients';
import IndividualClient from './components/ClientsComponents/IndividualClient';
import IndividualFolder from './components/FolderComponents/IndividualFolder';
import ForgotPassword from './components/ForgotPassword';
import Layout from './components/Layout';
import Login from './components/Login';
import NotFound from './components/NotFound';
import Portal from './components/Portal';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import SharedFoldersView from './components/SharedComponents/SharedFoldersView';
import SharedPortal from './components/SharedComponents/SharedPortal';
import SharedSubfoldersView from './components/SharedComponents/SharedSubfoldersView';

function App() {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<Layout />}>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="reset-password/:magicId" element={<ResetPassword />} />

        {/* Portal */}
        <Route path="portal" element={<Portal />}>
          <Route path="clients" element={<Clients />} />
          <Route path="clients/:clientId" element={<IndividualClient />} />
          <Route path="clients/:clientId/*" element={<IndividualFolder />} />
        </Route>

        {/* Shared view */}
        <Route path="shared" element={<SharedPortal />}>
          <Route path=":folderId" element={<SharedFoldersView />} />
          <Route path=":folderId/*" element={<SharedSubfoldersView />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;