import React, { useEffect, useState } from "react";
import { Gallery } from "react-grid-gallery";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import FolderTreeMain from "../FolderTree/FolderTreeMain";


export default function ImagesNew({ photos, currentFolderId }) {
  const [images, setImages] = useState([]);
  const [openMove, setOpenMove] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  //Get the current url using react-router
  const location = useLocation()
  //Check if location.path name mentions shared at the start
  const isShared = location.pathname.startsWith('/shared')

  useEffect(() => {
    const formattedImages = photos.map(image => {
      const calculatedWidth = image.height > image.width ? 120 : 250
      return {
        id: image._id,
        src: image.signedUrl,
        height: 180,
        width: calculatedWidth,
        originalImageId: image.originalImageId,
        imageId: image._id,
        isSelected: false,
        caption: image.fileName.replace('.webp', ''),
        thumbnailCaption: (
          <span style={{ maxWidth: calculatedWidth }} className="block text-center w-full whitespace-nowrap overflow-hidden text-slate-900 text-ellipsis">
            {image.fileName.replace('.webp', '')}
          </span>
        ),
      }
    })
    setImages(formattedImages)
  }, [photos])

  const queryClient = useQueryClient()
  const axiosPrivate = useAxiosPrivate()

  const deleteImagesFn = async ({ imageIds }) => {
    if (!imageIds) {
      //Toastr using react-toastify
      toast.error('Please select images to delete')
      return console.error('No images provided')
    }
    const response = await axiosPrivate.delete(`/images`, { data: { imageIds, currentFolderId } })
    queryClient.invalidateQueries({ queryKey: ['getCurrentFolderDetails'] })
    return response
  }

  //Mutation to delete images
  const deleteImagesMutation = useMutation(deleteImagesFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getImagesBasedOnFolderId'] })
      toast.success('Deletion successful...')
    },
    onError: (err) => {
      toast.error('There was an error while deleting the images. Please try again later')
      console.error('err: at delete images mutation 🛑', JSON.stringify(err, null, 2))
    }
  })



  const handleSelect = (index, item, event) => {
    const nextImages = images.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setImages(nextImages);
    if (nextImages.every(image => !image.isSelected)) {
      setOpenMove(false)
      setSelectedImages([])
    }
  };

  const handleDelete = () => {
    const selectedImages = images.filter(image => image.isSelected)
    if (selectedImages.length > 0) {
      const optimizedIds = selectedImages.map(image => image.id)
      const originalImageIds = selectedImages.map(image => image.originalImageId)
      const ids = [...optimizedIds, ...originalImageIds]
      deleteImagesMutation.mutate({ imageIds: ids })
    } else {
      toast.error('Please select images to delete')
    }
  }

  const handleMove = () => {
    const selectedImages = images.filter(image => image.isSelected)
    setSelectedImages(selectedImages)
    if (selectedImages.length > 0) {
      setOpenMove(true)
    } else {
      toast.error('Please select images to move')
    }
  }

  const handleSelectAll = () => {
    const nextImages = images.map((image) => {
      return { ...image, isSelected: true }
    });
    setImages(nextImages);
  }

  return (
    <>
      {/* Buttons for delete, move and delete all */}
      {!isShared ? <div className="flex items-center my-4">
        <div className="flex space-x-4 ">
          <button
            type="button"
            className="inline-flex items-center rounded-md border-2 border-gray-100/20 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-50 hover:text-gray-900"
            onClick={handleDelete}
          >
            Delete
          </button>
          <button
            type="button"
            className="inline-flex items-center rounded-md border-2 border-gray-100/20 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-50 hover:text-gray-900"
            onClick={handleMove}
          >
            Move
          </button>
          <button
            type="button"
            className="inline-flex items-center rounded-md border-2 border-gray-100/20 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-50 hover:text-gray-900"
            onClick={handleSelectAll}
          >
            Select All
          </button>
        </div>
      </div> : null}

      {/* Moving panel */}
      {(openMove && selectedImages.length > 0) ? <div>
        <FolderTreeMain selectedImages={selectedImages} setOpenMove={setOpenMove} currentFolderId={currentFolderId} />
      </div> : null}

      {/* Images */}
      {images.length > 0 ? <div className="max-h-screen overflow-y-scroll">
        <Gallery
        images={images}
        onSelect={handleSelect}
        enableImageSelection={isShared ? false : true}
      />
      </div> : <p>No images found</p>}
    </>
  )

}