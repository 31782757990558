import { Dialog, Transition } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { Fragment } from 'react'
import { useForm } from 'react-hook-form'
import QRCode from 'react-qr-code'
import { toast } from 'react-toastify'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { sharableLinkSchema } from '../Schemas/sharableLinkSchema'

export default function SharableLinkModal({
  open,
  setOpen,
  sharableString,
  folder,
  currentClient,
}) {
  const axiosPrivate = useAxiosPrivate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(sharableLinkSchema),
    defaultValues: {
      email: currentClient?.email || '',
      ccEmail: currentClient?.ccEmail || '',
      sharableLink: sharableString || '',
    },
  })

  const onSubmit = async (data) => {
    try {
      data.clientName = currentClient.name || 'There'
      data.folderName = folder.name
      data.ccEmail = data.ccEmail || ''
      const payload = {
        from: 'nayan@ncpictures.ca',
        to: data.email,
        data,
      }
      //Send this to BE to send email
      const response = await axiosPrivate.post('/email/publish', payload)
      if (response.status === 200) {
        setOpen(false)
        //toastr message
        toast.success('Email sent successfully')
      }
    } catch (error) {
      console.error('error:', error.response)
      toast.error('Something went wrong')
    } finally {
      setOpen(false)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 min-w-[85vw] md:min-w-max'>
                <div className='sm:flex sm:items-start'>
                  <div className='flex-1 mt-3 text-center sm:ml-4 sm:mt-0'>
                    <Dialog.Title
                      as='h3'
                      className='text-lg font-semibold leading-6 text-gray-900'
                    >
                      Share folder: {folder.name}
                    </Dialog.Title>
                    <div className='flex text-center w-36 mx-auto my-8'>
                      <QRCode
                        style={{
                          height: 'auto',
                          maxWidth: '100%',
                          width: '100%',
                        }}
                        value={sharableString}
                        size={128}
                      />
                    </div>
                    <div className='mt-2'>
                      {/* FORM HERE */}
                      <form
                        className='space-y-6 mt-8'
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        {/* Email */}
                        <div>
                          <label
                            htmlFor='email'
                            className='text-left block text-sm font-medium text-gray-700'
                          >
                            Email address
                          </label>
                          <div className='mt-1'>
                            <input
                              id='email'
                              name='email'
                              type='email'
                              autoComplete='email'
                              {...register('email')}
                              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm'
                            />
                          </div>
                          {<p>{errors?.email?.message}</p>}
                        </div>
                        {/* CC Email */}
                        <div>
                          <label
                            htmlFor='ccEmail'
                            className='text-left block text-sm font-medium text-gray-700'
                          >
                            CC: Email address
                          </label>
                          <div className='mt-1'>
                            <input
                              id='ccEmail'
                              type='email'
                              name='ccEmail'
                              autoComplete='email'
                              {...register('ccEmail')}
                              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm'
                            />
                          </div>
                        </div>
                        {/* Sharable link */}
                        <div>
                          <label
                            htmlFor='sharableLink'
                            className='text-left block text-sm font-medium text-gray-700'
                          >
                            Sharable Link
                          </label>
                          <div className='mt-1'>
                            <input
                              id='sharableLink'
                              name='sharableLink'
                              type='text'
                              autoComplete='false'
                              {...register('sharableLink')}
                              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm'
                            />
                          </div>
                          <p>{errors?.sharableLink?.message}</p>
                        </div>
                        {/* Youtube link */}
                        <div>
                          <label
                            htmlFor='youtubeLink'
                            className='text-left block text-sm font-medium text-gray-700'
                          >
                            Youtube Link
                          </label>
                          <div className='mt-1'>
                            <input
                              id='youtubeLink'
                              name='youtubeLink'
                              type='text'
                              autoComplete='false'
                              {...register('youtubeLink')}
                              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm'
                            />
                          </div>
                        </div>

                        {/* RMS link */}
                        <div>
                          <label
                            htmlFor='rmsLink'
                            className='text-left block text-sm font-medium text-gray-700'
                          >
                            RMS Link
                          </label>
                          <div className='mt-1'>
                            <input
                              id='rmsLink'
                              name='rmsLink'
                              type='text'
                              autoComplete='false'
                              {...register('rmsLink')}
                              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm'
                            />
                          </div>
                        </div>

                        {/* Text message box */}
                        <div>
                          <label
                            htmlFor='message'
                            className='text-left block text-sm font-medium text-gray-700'
                          >
                            Message
                          </label>
                          <div className='mt-1'>
                            <textarea
                              id='message'
                              name='message'
                              rows={4}
                              className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm'
                              aria-describedby='message-max'
                              defaultValue={''}
                              {...register('message')}
                            />
                          </div>
                        </div>

                        {/* Submit button */}

                        <div className='flex space-x-4'>
                          <button
                            className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto'
                            onClick={() => setOpen(false)}
                          >
                            Cancel
                          </button>
                          <button
                            type='submit'
                            className='mt-3 inline-flex w-full justify-center rounded-md bg-gray-900 text-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-800 sm:mt-0 sm:w-auto'
                          >
                            {false ? (
                              <svg
                                className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                              >
                                <circle
                                  className='opacity-25'
                                  cx='12'
                                  cy='12'
                                  r='10'
                                  stroke='currentColor'
                                  strokeWidth='4'
                                ></circle>
                                <path
                                  className='opacity-75'
                                  fill='currentColor'
                                  d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                ></path>
                              </svg>
                            ) : (
                              'Publish'
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
