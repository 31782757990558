import React from 'react'
import { Outlet } from 'react-router-dom'

function SharedPortal() {
  return (
    <div className='h-full min-h-screen flex-1 p-4'>
      {/* Header */}
      <div className='flex py-4 text-gray-100/80 items-end justify-between border-b border-gray-500'>
        <h2 className='text-xl font-semibold'>NC Pictures</h2>
        <p className='text-md'>Thank you for the business!</p>
      </div>
      {/* Main Contents of folder */}
      <main>
        <Outlet />
      </main>
    </div>
  )
}

export default SharedPortal