import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Link } from 'react-router-dom'

export default function Breadcrumbs({ pages }) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <Link to='/portal/clients' className="text-gray-400 hover:text-red-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <Link
                to={page.href}
                className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                aria-current={page.current ? 'page' : undefined}
              >
                {page.name}
                {/* {index === pages.length - 1 && folderStatus && <span className='text-red-500'>{folderStatus?.status}</span>} */}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
