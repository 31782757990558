import { FolderIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
// import streamToBlob from 'stream-to-blob'
// import { createWriteStream } from 'streamsaver'


function SharedFolder({ folder }) {
  const location = useLocation()
  const currentLocation = location.pathname
  const futureLocation = `${currentLocation}/${folder.slug}`

  return (
    <div className='flex flex-col space-y-8'>
      <div className='flex'>
        <div
          key={folder.name}
          className="p-4 relative flex-1 flex rounded-lg rounded-r-none border border-r-0 border-gray-300/20 bg-black/10 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <div className='flex-1'>
            <Link to={`${futureLocation}`} className="flex space-x-4 items-center focus:outline-none">
              <div className="flex-shrink-0">
                <FolderIcon className="h-6 w-6 rounded-full text-yellow-600" />
              </div>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col flex-1'>
                  <h2 className=" font-medium text-gray-50">{folder.name}</h2>
                  <p className='text-gray-50/70'>Created on: {new Date(folder.createdAt).toLocaleDateString('en-IN')}</p>
                </div>
              </div>
            </Link>
          </div>

        </div>
        {/* Right side */}
        {
          <div className='flex items-center border border-l-0 border-gray-300/20 space-x-4 pr-4 bg-black/10 hover:border-gray-400'>
            {folder.zipFilePath ? <a
              href={`${process.env.REACT_APP_CF_URL}/${folder.zipFilePath}`}
              className="block rounded bg-green-600 px-3 py-4 font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 text-sm disabled:opacity-50 disabled:cursor-not-allowed"
            > Download
            </a> : null}
          </div>
        }
      </div>
    </div>
  )
}

export default SharedFolder