import { UserIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Modal from '../Helpers/Modal';
import EditClient from './EditClient';

export default function ClientsList({ clients }) {
  const queryClient = useQueryClient()
  const axiosPrivate = useAxiosPrivate()
  const [openClient, setOpenClient] = useState(false)
  const [currentClientId, setCurrentClientId] = useState(null)
  const [slideEditStatus, setEditSlideStatus] = React.useState(false)
  const [currentClient, setCurrentClient] = useState(null)

  const openDeleteModal = (clientId) => {
    setOpenClient(true)
    setCurrentClientId(clientId)
  }

  const handleClientDelete = () => {
    deleteClientMutation.mutate({ currentClientId })
  }

  const deleteClientFn = async ({ currentClientId }) => {
    if (!currentClientId) {
      //Toastr using react-toastify
      toast.error('There was an error while deleting the client. Please try again later')
      return console.error('No client id provided')
    }
    return await axiosPrivate.delete(`/clients/${currentClientId}`)
  }

  //Mutation to delete the client
  const deleteClientMutation = useMutation(deleteClientFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getClients'] })
      setOpenClient(false)
      toast.success('Deletion successful...')
    },
    onError: (err) => {
      toast.error('There was an error while deleting the client. Please try again later')
      console.error('err: at delete client mutation 🛑', JSON.stringify(err, null, 2))
    }
  })

  const handleEdit = (clientDetails) => {
    setCurrentClient(clientDetails)
    setEditSlideStatus(true)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {clients.length > 0 && clients.map((client, index) => (
        <div
          key={`client.name-${index}`}
          className="relative flex items-center space-x-3 rounded-lg border border-gray-300/20 bg-black/10 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
        >
          <div className="flex-shrink-0">
            <UserIcon className="h-6 w-6 rounded-full text-orange-500" />
          </div>
          <div className="min-w-0 flex-1">
            <div>
              <Link to={`/portal/clients/${client._id}`} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-50/80 overflow-hidden">{client.name}</p>
              </Link>
              <p className='text-gray-400'>{client.email}</p>
              <p className='text-gray-400'>{client.ccEmail}</p>
            </div>
          </div>
          <div className='z-[5] space-x-4'>
            <button onClick={() => {
              handleEdit(
                {
                  clientId: client._id,
                  clientName: client.name,
                  clientEmail: client.email,
                  ccEmail: client?.ccEmail || ''
                })
            }} className='p-2 px-4 text-sm bg-green-700 hover:bg-green-600 text-white rounded'>Edit</button>
            <button onClick={() => {
              openDeleteModal(client._id)
            }} className='p-2 px-4 text-sm bg-darkAscent-100 hover:bg-red-500 text-white rounded'>Delete</button>
          </div>
        </div>
      ))
      }
      {clients.length === 0 && <p className='text-center text-gray-500'>No clients found</p>}
      {/* Modal */}
      <Modal open={openClient} setOpen={setOpenClient} heading={'Delete Client'} body={'Are you sure you want to delete the folder and its associated images?'} actionFn={handleClientDelete} />

      {/* Slide over for editing the client */}
      <EditClient slideEditStatus={slideEditStatus} setEditSlideStatus={setEditSlideStatus} currentClient={currentClient} />
    </div>
  )
}
