import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { axiosPrivate } from '../api/axios'
import ClientsList from './ClientsComponents/ClientsList'
import CreateClient from './ClientsComponents/CreateClient'
import ErrorAlert from './Helpers/ErrorAlert'
import SecondaryBtn from './Helpers/SecondaryBtn'

function Clients() {
  // Component State
  const [slideStatus, setSlideStatus] = React.useState(false)
  function OpenCreateClientSlide() {
    setSlideStatus(true)
  }

  const fetchClients = async () => {
    const response = await axiosPrivate.get('/clients')
    return response
  }
  const queryClient = useQueryClient()
  const { data, isError, isLoading, isSuccess } = useQuery('getClients', fetchClients)
  if (isSuccess) {
    queryClient.resetQueries('getClientFolder')
  }

  return (
    // PATH: clients
    <div className="flex-1 flex items-stretch overflow-hidden flex-col lg:flex-row">
      <section aria-labelledby="primary-section" className="min-w-0 flex-1 h-full flex flex-col lg:order-last p-4 md:p-8">
        <div className="pb-5 border-b border-gray-50/40 flex justify-between">
          <h3 className="text-lg leading-6 font-medium text-gray-100/80">Clients</h3>
          <SecondaryBtn btnText="+ Clients" onClick={OpenCreateClientSlide} />
        </div>
        {/* Slide over for creating a client */}
        <CreateClient slideStatus={slideStatus} setSlideStatus={setSlideStatus} />
        <div className='space-y-4 mt-4'>
          {isLoading && <p>Loading...</p>}
          {isError && <ErrorAlert message="There is an error occurred while getting all the clients list. Please re-login and try again" />}
          {isSuccess && data?.data?.clients.length > 0 && <ClientsList clients={data.data.clients} />}
          {isSuccess && data?.data?.clients.length === 0 && <p>No clients found</p>}
        </div>
      </section>
    </div>
  )
}

export default Clients