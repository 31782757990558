import React from 'react'

function TertiaryBtn({ btnText, onClick, disabled }) {
  return (
    <button
      type="button"
      className="rounded-md border-2 border-gray-100/20 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-50 hover:text-gray-900"
      onClick={onClick}
      disabled={disabled}
    >
      {btnText}
    </button>
  )
}

export default TertiaryBtn