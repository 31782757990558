import { FolderIcon } from '@heroicons/react/24/solid'
import React, { useState } from 'react'
import { BsPencil, BsShare } from 'react-icons/bs'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Link, useLocation, useParams } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import EditFolderModal from './EditFolderModal'
import Modal from './Modal'
import SharableLinkModal from './SharableLinkModal'


function Folder({ folder }) {
  const { auth } = useAuth()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const queryClient = useQueryClient()
  const currentLocation = location.pathname
  const futureLocation = `${currentLocation}/${folder.slug}`
  const [open, setOpen] = useState(false)
  const [openSharableLinkModal, setOpenSharableLinkModal] = useState(false)
  const [sharableString, setSharableString] = useState('')

  // Edit modal state
  const [openEditModal, setOpenEditModal] = useState(false)

  const params = useParams()
  const clientId = params.clientId
  //Once you get the client id get the details of the client using react-query
  const { data: clientData, isLoading, isError, error } = useQuery(['client', clientId], () => axiosPrivate.get(`/clients/${clientId}`), {
    enabled: !!clientId,
  })

  const currentClientInfo = clientData?.data

  const handleDelete = () => {
    setOpen(true)
  }

  const deleteFolderFn = async () => {
    return await axiosPrivate.delete(`/folders/${folder._id}`)
  }

  //Mutation to delete the folder id
  const deleteFolderMutation = useMutation(deleteFolderFn, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getClientFolder', folder.parentFolder] })
      setOpen(false)
    },
    onError: (err) => {
      console.error('err: at delete folder mutation 🛑', JSON.stringify(err, null, 2))
    }
  })

  const handleShare = (folder) => {
    const sharableString = `${window.location.origin}/shared/${folder.shareableLink}`
    setSharableString(sharableString)
    setOpenSharableLinkModal(true)
  }

  const handleFolderDelete = () => {
    deleteFolderMutation.mutate()
  }

  const handleEdit = () => {
    setOpenEditModal(true)
  }


  return (
    <div className='flex'>
      <div className='flex flex-col w-full md:flex-row'>
        {/* Left Side */}
        <div
          key={folder.name}
          className={`p-4 relative flex-1 flex rounded-lg rounded-r-none border md:border-r-0 border-gray-300/20 bg-black/10 shadow-sm hover:border-gray-400 ${folder.zipFileDate < folder.uploadDate ? 'border-red-500' : ''} `}
        >
          <div className='flex-1'>
            <Link to={`${futureLocation}`} className="flex space-x-2 items-center focus:outline-none">
              <div className="flex-shrink-0">
                <FolderIcon className="h-6 w-6 rounded-full text-yellow-600" />
              </div>
              <div className='flex justify-between items-center'>
                <div className='flex flex-col flex-1'>
                  <h2 className=" font-medium text-gray-50">{folder.name}</h2>
                  <p className='text-gray-50/70'>Created on: {new Date(folder.createdAt).toLocaleDateString('en-IN')}</p>
                </div>
              </div>
            </Link>
          </div>

        </div>
        {/* Right side */}
        {auth?.name && <div className='flex items-center border md:border-l-0 border-gray-300/20 space-x-3 pr-4 bg-black/10 hover:border-gray-400'>
          <button
            type="button"
            onClick={() => handleEdit()}
            className="block border border-gray-700 rounded bg-darkAscent-100 hover:bg-green-600 p-3 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
          > <BsPencil />
          </button>
          {folder.zipFilePath ? <button
            type="button"
            onClick={() => handleShare(folder)}
            className="block rounded border border-gray-700 bg-darkAscent-100 text-md font-semibold text-white shadow-sm hover:bg-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 p-3"
          > <BsShare />
          </button> : null}
          <button
            type="button"
            onClick={() => handleDelete()}
            className="block rounded border border-gray-700 bg-darkAscent-100 hover:bg-rose-600 p-3 text-md font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-500"
          > <FaRegTrashAlt />
          </button>


        </div>}
      </div>

      {/* Modal Delete*/}
      <Modal open={open} setOpen={setOpen} heading={'Delete Folder'} body={'Are you sure you want to delete the folder and its associated images?'} actionFn={handleFolderDelete} />

      {/* Modal Sharable link */}
      {openSharableLinkModal && <SharableLinkModal open={openSharableLinkModal} setOpen={setOpenSharableLinkModal} sharableString={sharableString} folder={folder} currentClient={currentClientInfo} />}

      {/* Edit Folder */}
      {openEditModal && <EditFolderModal open={openEditModal} setOpen={setOpenEditModal} folder={folder} />}
    </div>
  )
}

export default Folder