import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from 'react-query';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ErrorAlert from '../Helpers/ErrorAlert';

export default function CreateClientFolder({ slideStatus, setSlideStatus, parentFolder, isParentRoot = false }) {
  const [error, setError] = useState(null)
  const queryClient = useQueryClient()
  const { register, handleSubmit } = useForm();
  const axiosPrivate = useAxiosPrivate()

  //Mutation function to create a new client
  const clientMutationFn = async (payload) => {
    return await axiosPrivate.post('/folders', payload)
  }

  // Mutation to create a new client
  const clientFolderMutation = useMutation(clientMutationFn, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['getClientFolder'] })
      queryClient.invalidateQueries({ queryKey: ['getCurrentFolderDetails'] })
      setSlideStatus(false)
    }, onError: (err) => {
      setSlideStatus(true)
      console.error('err: at client mutation 🛑', JSON.stringify(err, null, 2))
      setError('Error while creating the client')
    },
  })

  // FUnction to handle client form submission
  const handleClientFolderFormSubmission = ({ folderName }) => {
    setError(null)
    if (!folderName) {
      return setError('Missing Client Name')
    }
    clientFolderMutation.mutate({ folderName, parentFolder, isParentRoot: isParentRoot })
  }
  return (
    <Transition.Root show={slideStatus} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setSlideStatus}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form onSubmit={handleSubmit(handleClientFolderFormSubmission)} className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                              Create a new folder for a client
                            </Dialog.Title>
                            <p className="text-sm text-gray-500">
                              A new folder will be created for the client.
                            </p>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => setSlideStatus(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* Folder Name */}
                        <div className="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              htmlFor="folder-name"
                              className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >
                              Folder name
                            </label>
                          </div>
                          <div className="sm:col-span-2">
                            <input
                              type="text"
                              name="folder-name"
                              id="folder-name"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 sm:text-sm sm:leading-6"
                              {...register("folderName")}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {error && <ErrorAlert message={error} />}
                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                          onClick={() => setSlideStatus(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
