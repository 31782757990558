import { XCircleIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Link, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import { axiosPrivate } from '../../api/axios'
import CreateClientFolder from '../ClientsComponents/CreateClientFolder'
import Breadcrumbs from '../Helpers/Breadcrumbs'
import DocumentUploaderDirect from '../Helpers/DocumentUploaderDirect'
import ErrorAlert from '../Helpers/ErrorAlert'
import Folder from '../Helpers/Folder'
import Heading from '../Helpers/Heading'
import PDFUploader from '../Helpers/PDFuploader'
import SecondaryBtn from '../Helpers/SecondaryBtn'
import SharableLinkModal from '../Helpers/SharableLinkModal'
import TertiaryBtn from '../Helpers/TertiaryBtn'
import VideoUploader from '../Helpers/VideoUploader'
import ImagesNew from '../ImagesComponents/ImagesNew'
import Videos from '../VideoComponents/Videos'

function IndividualFolder() {
  const [slideStatus, setSlideStatus] = React.useState(false)
  const [fileUploader, setFileUploader] = React.useState(true)
  const [currentOptimizationSocketId, setCurrentOptimizationSocketId] =
    useState('')
  const [optimizationStatus, setOptimizationStatus] = useState(null)
  // const [downloadProgress, setDownloadProgress] = useState(0);
  const [VideoUploaderSection, setVideoUploaderSection] = useState(true)
  const [zipLoading, setZipLoading] = useState(false)
  const [zipProgress, setZipProgress] = useState('')
  const [sharableString, setSharableString] = useState('')
  const [openSharableLinkModal, setOpenSharableLinkModal] = useState(false)

  const params = useParams()
  const folderParams = params['*']
  const currentSlug = folderParams.split('/').pop()
  const queryClient = useQueryClient()
  let currentPathForS3 = ''

  //Get the current url using react-router
  const location = useLocation()
  //Check if location.path name mentions shared at the start
  const isShared = location.pathname.startsWith('/shared')

  // Setup socket
  const url = process.env.REACT_APP_BASE_URL?.toString()
  const socket = io(url, {
    autoConnect: false,
  })

  useEffect(() => {
    // setDownloadProgress(0);
    socket.connect()
    socket.on('connect', () => {
      setCurrentOptimizationSocketId(socket.id)
      socket.emit('join_optimization_room', { room: socket.id })
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  socket.on('optimization_image_name', (message) => {
    setOptimizationStatus(message.message)
  })

  socket.on('zip_controller', (message) => {
    setZipProgress(message)
  })

  //Get client details from the database
  async function getSingleClient() {
    const { data } = await axiosPrivate.get(`/clients/${params.clientId}`)
    return data
  }

  const { data, isError, isLoading, isSuccess } = useQuery(
    'getClient',
    getSingleClient,
  )
  const clientData = data

  currentPathForS3 += `${clientData?.name}/`
  const pages = [
    {
      name: `${clientData?.name}`,
      href: `/portal/clients/${clientData?._id}`,
      current: false,
      slug: null,
    },
  ]

  //update the pages array with the path name from the folderParams
  folderParams.split('/').forEach((folder, index) => {
    if (index === 0) {
      pages.push({
        name: folder.slice(0, -7),
        href: `/portal/clients/${params.clientId}/${folder}`,
        current: false,
        slug: folder,
      })
    } else {
      pages.push({
        name: folder.slice(0, -7),
        href: `/portal/clients/${params.clientId}/${folderParams
          .split('/')
          .slice(0, index + 1)
          .join('/')}`,
        current: false,
        slug: folder,
      })
    }
    currentPathForS3 += `${folder.slice(0, -7)}/`
  })

  //get the folder details based on slug provided in the url - Basically parent folder details
  async function getCurrentFolderDetails() {
    const { data } = await axiosPrivate.get(`/folders/slug/${currentSlug}`)
    return data
  }

  const {
    data: folderData,
    isError: isErrorFolder,
    isLoading: isLoadingFolder,
    isSuccess: isSuccessFolder,
  } = useQuery(
    ['getCurrentFolderDetails', currentSlug],
    getCurrentFolderDetails,
  )

  const currentFolderId =
    !isErrorFolder && isSuccessFolder && folderData.length
      ? folderData[0]._id
      : null

  //Get the current folder's children
  async function getCurrentFoldersChildren(currentFolderId) {
    const { data } = await axiosPrivate.get(`/folders/${currentFolderId}`)
    return data
  }

  const {
    data: currentFoldersChildrenData,
    isError: isErrorCurrentFoldersChildrenData,
    isLoading: isLoadingCurrentFoldersChildrenData,
    isSuccess: isSuccessCurrentFoldersChildrenData,
  } = useQuery({
    queryKey: ['getCurrentFolderDetails', currentFolderId],
    queryFn: () => getCurrentFoldersChildren(currentFolderId),
    enabled: !!currentFolderId,
  })

  //Get the images based on folder id
  async function getImagesBasedOnFolderId(folderId) {
    if (!folderId) return
    const { data } = await axiosPrivate.get(`/images/${folderId}`)
    return data
  }

  const {
    data: imagesData,
    isError: isErrorImagesData,
    isLoading: isLoadingImagesData,
    isSuccess: isSuccessImagesData,
  } = useQuery({
    queryKey: ['getImagesBasedOnFolderId', currentFolderId],
    queryFn: () => getImagesBasedOnFolderId(currentFolderId),
    enabled: !!currentFolderId,
    refetchOnWindowFocus: true,
  })

  //Get the  videos based on folder id
  async function getVideosBasedOnFolderId(folderId) {
    if (!folderId) return
    const { data } = await axiosPrivate.get(`/videos/${folderId}`)
    return data
  }

  const {
    data: videosData,
    isError: isErrorVideosData,
    isLoading: isLoadingVideosData,
    isSuccess: isSuccessVideosData,
  } = useQuery({
    queryKey: ['getVideosBasedOnFolderId', currentFolderId],
    queryFn: () => getVideosBasedOnFolderId(currentFolderId),
    enabled: !!currentFolderId,
    refetchOnWindowFocus: true,
  })

  // console.log('videosData:', videosData)

  // Get the PDF files based on folder id
  async function getPdfsBasedOnFolderId(folderId) {
    if (!folderId) return
    const { data } = await axiosPrivate.get(`/pdfs/${folderId}`)
    return data
  }

  const {
    data: pdfsData,

    isError: isErrorPdfsData,
    isLoading: isLoadingPdfsData,
    isSuccess: isSuccessPdfsData,
  } = useQuery({
    queryKey: ['getPdfsBasedOnFolderId', currentFolderId],
    queryFn: () => getPdfsBasedOnFolderId(currentFolderId),
    enabled: !!currentFolderId,
    refetchOnWindowFocus: true,
  })

  // console.log('pdfsData:', pdfsData)

  function OpenCreateClientFolderSlide() {
    setSlideStatus(true)
  }

  function OpenFileUploaderSlide() {
    setFileUploader(true)
  }

  async function createZipFolder() {
    try {
      if (!videosData?.length && !imagesData?.length) {
        toast.error('There are no images or videos to zip')
        return
      }
      setZipLoading(true)
      const currentFolderSlug = currentSlug
      const response = await axiosPrivate.post('/createZip', {
        folderSlug: currentFolderSlug,
        currentPathForS3,
        socketId: currentOptimizationSocketId,
      })
      if (response.status === 200) {
        toast.success('Zip folder created successfully')
        //invalidate the folder query
        queryClient.invalidateQueries(['getCurrentFolderDetails', currentSlug])
      } else {
        toast.error(
          'There was an error while creating the zip folder. Please try again later',
        )
      }
    } catch (e) {
      console.log('error while creating zip folder:', e)
      toast.error(
        'There was an error while creating the zip folder. Please try again later',
      )
    } finally {
      setZipLoading(false)
    }
  }

  function openVideoUploader() {
    setVideoUploaderSection(true)
  }

  const handleShare = (sharableLink) => {
    const sharableString = `${window.location.origin}/shared/${sharableLink}`
    setSharableString(sharableString)
    setOpenSharableLinkModal(true)
  }

  const handleDeletePdf = (pdfId) => {
    console.log('pdfId:', pdfId)
    try {
      if (!pdfId) {
        toast.error('No PDF fie to delete')
        return
      }
      axiosPrivate.delete(`/pdfs/${pdfId}`)
      queryClient.invalidateQueries({
        queryKey: ['getPdfsBasedOnFolderId'],
      })
      toast.success('File deleted successfully')
    } catch (error) {
      console.log('Error deleting pdf', error)
    }
  }

  return (
    // PATH: clients/:clientId/*
    <div className='flex-1 flex flex-col items-stretch overflow-hidden p-4 space-y-6 md:p-8'>
      <section aria-labelledby='primary-section' className='flex flex-col'>
        <div className='pb-5 border-b border-gray-100/40 flex flex-col md:flex-row space-y-2 md:space-y-0  justify-between'>
          <div className='flex flex-col space-y-2'>
            {isLoading && (
              <h3 className='text-lg leading-6 font-medium text-gray-900'>
                Getting the client details...
              </h3>
            )}
            {isError && (
              <ErrorAlert message='Error getting a client details!' />
            )}
            {isSuccess && clientData.name && (
              <h3 className='text-lg leading-6 font-medium text-gray-50'>
                Client Name: {clientData.name}
              </h3>
            )}
          </div>
          <div className='flex flex-col space-y-4 md:flex-row md:space-x-2 md:space-y-0'>
            <TertiaryBtn
              btnText='Create Folder'
              onClick={OpenCreateClientFolderSlide}
            />
            <TertiaryBtn
              btnText={
                zipLoading
                  ? zipProgress
                    ? zipProgress
                    : 'Creating...'
                  : 'Create Zip'
              }
              onClick={createZipFolder}
              disabled={zipLoading}
            />
            {folderData?.length > 0 && (
              <Link
                className='block text-center rounded-md border-2 border-gray-100/20 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-50 hover:text-gray-900'
                to={`${process.env.REACT_APP_CF_URL}/${folderData[0]?.zipFilePath}`}
              >
                Download
              </Link>
            )}
            {folderData?.length && folderData[0].zipFilePath ? (
              <TertiaryBtn
                btnText='Share Folder'
                onClick={() => handleShare(folderData[0].shareableLink)}
              />
            ) : null}
          </div>
        </div>
        {/* Slide over for creating a client */}
        <CreateClientFolder
          slideStatus={slideStatus}
          setSlideStatus={setSlideStatus}
          parentFolder={currentFolderId}
          isParentRoot={false}
        />
      </section>

      {/* Breadcrumb */}
      <div className='flex'>
        <div className='flex-1'>
          {/* Breadcrumb here!! */}
          {isSuccess && clientData ? (
            <Breadcrumbs pages={pages} />
          ) : (
            <p>Loading the breadcrumb...</p>
          )}
        </div>
        {/* Show downloading progress - This approach is no longer in use*/}
        {/* {(downloadProgress > 0) && <div className='text-black bg-gray-200 p-2'>
          <p>Downloading: {downloadProgress} MB</p>
        </div>} */}
      </div>

      {/* Folders zip and upload details */}
      {isSuccessFolder && folderData.length ? (
        <div className='space-y-4 mt-4 border border-orange-100/50 bg-[#172136] p-4 rounded-md'>
          <Heading heading='Folder details' />
          {isLoadingFolder && <p>Loading...</p>}
          {isErrorFolder && (
            <ErrorAlert message='There is an error ocurred while getting the folders details.' />
          )}
          <div>
            <h1>
              Zip Creation Date:{' '}
              {folderData[0].zipFileDate
                ? new Date(folderData[0].zipFileDate).toLocaleString('en-IN')
                : '-'}
            </h1>
            <h1>
              Upload Date:{' '}
              {folderData[0].uploadDate
                ? new Date(folderData[0].uploadDate).toLocaleString('en-IN')
                : '-'}
            </h1>
            {/* If zip creation date is earlier than the upload date, show warning */}
            {folderData[0].zipFileDate &&
              folderData[0].uploadDate &&
              new Date(folderData[0].zipFileDate) <
                new Date(folderData[0].uploadDate) && (
                <div className='rounded-md bg-red-50 p-4 mt-4'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <XCircleIcon
                        className='h-5 w-5 text-red-400'
                        aria-hidden='true'
                      />
                    </div>
                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-red-800'>
                        Warning: Zip file is older than the upload date. Please
                        create a new zip file.
                      </h3>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : null}

      {/* Folders */}
      {isSuccessCurrentFoldersChildrenData &&
      currentFoldersChildrenData.length ? (
        <div className='space-y-4 mt-4 border border-orange-100/50 bg-[#172136] p-4 rounded-md'>
          {isSuccessCurrentFoldersChildrenData &&
          currentFoldersChildrenData.length ? (
            <Heading heading='Folders' />
          ) : null}
          {isLoadingCurrentFoldersChildrenData && <p>Loading...</p>}
          {isErrorCurrentFoldersChildrenData && (
            <ErrorAlert message="There is an error ocurred while getting the client and it's folders." />
          )}
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            {isSuccessCurrentFoldersChildrenData &&
            currentFoldersChildrenData.length
              ? currentFoldersChildrenData.map((folder) => (
                  <span key={folder._id}>
                    <Folder folder={folder} />
                  </span>
                ))
              : null}
          </div>
        </div>
      ) : null}

      {/* Images section */}
      <div className='border rounded-md p-4 border-gray-100/10 pt-8'>
        <div className='text-gray-50 flex justify-between'>
          <Heading heading='Images' />
          <div className='flex space-x-4'>
            {/* Optimization status display section */}
            {optimizationStatus && (
              <div className='text-black bg-gray-200 p-2'>
                <p>Optimizing: {optimizationStatus} </p>
              </div>
            )}
          </div>
        </div>
        {/* Section over for uploading files */}
        <div className='bg-gray-200 rounded-md p-4 text-gray-900 mt-4'>
          <DocumentUploaderDirect
            setFileUploader={setFileUploader}
            currentFolderId={currentFolderId}
            currentPathForS3={currentPathForS3}
            slug={pages[pages.length - 1]}
            currentOptimizationSocketId={currentOptimizationSocketId}
          />
        </div>
        <div className='overflow-hidden'>
          {isLoadingImagesData && <p>Loading...</p>}
          {isErrorImagesData && (
            <ErrorAlert message='There is an error ocurred while getting the images.' />
          )}
          {/* {imagesData && <Images photos={imagesData} />} */}
          {imagesData && (
            <ImagesNew photos={imagesData} currentFolderId={currentFolderId} />
          )}
        </div>
      </div>

      {/* Video Section */}
      <div className='border rounded-md p-4 border-gray-100/10 pt-8'>
        <div className='text-gray-50 flex justify-between items-center'>
          <Heading heading='Videos' />
        </div>
        {/* Section for video uploader */}
        {VideoUploaderSection && (
          <div className='bg-gray-200 rounded-md p-4 mt-4 text-gray-900'>
            <VideoUploader
              currentFolderId={currentFolderId}
              currentPathForS3={currentPathForS3}
              setVideoUploaderSection={setVideoUploaderSection}
            />
          </div>
        )}

        <div className='overflow-hidden'>
          {isLoadingVideosData && <p>Loading...</p>}
          {isErrorVideosData && (
            <ErrorAlert message='There is an error ocurred while getting the videos.' />
          )}
          {/* {videosData && <Videos videos={videosData} />} */}
          {videosData?.length > 0 ? (
            <Videos videosData={videosData} showDelete={true} />
          ) : (
            <p className='mt-4'>No Videos found</p>
          )}
        </div>
      </div>

      {/* RMS PDF Upload Section */}
      <div className='border rounded-md p-4 border-gray-100/10 pt-8'>
        <div className='text-gray-50 flex justify-between items-center'>
          <Heading heading='RMS File Uploads (PDF)' />
        </div>
        {/* Section for PDF file uploader */}
        <div className='bg-gray-200 rounded-md p-4 mt-4 text-gray-900'>
          <PDFUploader
            currentFolderId={currentFolderId}
            currentPathForS3={currentPathForS3}
          />
        </div>

        <div className='overflow-hidden'>
          {isLoadingVideosData && <p>Loading...</p>}
          {isErrorVideosData && (
            <ErrorAlert message='There is an error ocurred while getting the videos.' />
          )}
          {/* PDFs data */}
          {pdfsData && pdfsData.length ? (
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4'>
              {pdfsData.map((pdf) => (
                <div
                  key={pdf._id}
                  className='border border-gray-100/20 rounded-md p-4 space-y-4'
                >
                  <h1 className='text-gray-50 text-lg font-medium'>
                    {pdf.fileName}
                  </h1>
                  <div className='flex space-x-2'>
                    <a
                      className='block text-center rounded-md border-2 border-gray-100/20 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-50 hover:text-gray-900'
                      href={pdf.signedUrl}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Download
                    </a>
                    {!isShared ? (
                      <button
                        className='inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:opacity-50 disabled:cursor-not-allowed'
                        onClick={() => handleDeletePdf(pdf._id)}
                      >
                        Delete
                      </button>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className='mt-4'>No PDFs found</p>
          )}
        </div>
      </div>

      {/* Modal Sharable link */}
      {openSharableLinkModal && (
        <SharableLinkModal
          open={openSharableLinkModal}
          setOpen={setOpenSharableLinkModal}
          sharableString={sharableString}
          folder={folderData[0]}
          currentClient={clientData}
        />
      )}
    </div>
  )
}

export default IndividualFolder
