import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import uuid from 'uuid-random';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

function VideoUploader({ currentFolderId, currentPathForS3, setVideoUploaderSection }) {
  const [videoFileName, setVideoFileName] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [isEventOngoing, SetIsEventOngoing] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient()

  const uploadVideo = async () => {
    try {
      if (!videoFile) {
        toast.error('Please select a video file')
        return
      }
      SetIsEventOngoing(true);
      const randomId = uuid();
      //Form data preparation for upload
      const formData = new FormData();
      formData.append('file', videoFile[0]);
      formData.append('folderId', currentFolderId);
      formData.append('currentPathForS3', currentPathForS3);
      formData.append('randomId', randomId);

      const videoUploadResponse = await axiosPrivate.post(`/videoupload/?path=${currentPathForS3}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });

      if (videoUploadResponse.status === 200) {
        toast.success('Video uploaded successfully')
      } else {
        toast.error('Video upload failed')
      }
    } catch (error) {
      console.log('error uploading video:', error)
      console.log("Error uploading video 🛑", JSON.stringify(error, null, 2))
    } finally {
      setVideoFileName('')
      setVideoFile(null)
      SetIsEventOngoing(false)
      queryClient.invalidateQueries({ queryKey: ['getVideosBasedOnFolderId'] });
    }
  }

  const onDrop = useCallback(async (acceptedFiles, rejectedFiles) => {
    //upload to server
    setVideoFileName(acceptedFiles[0].name)
    setVideoFile(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/mp4': ['.mp4']
    },
    maxFiles: 1,
    onDropRejected: () => {
      console.log('File rejected based on type or max files limit.');
      toast.error('No more than 1 file please.');
    },
    disabled: videoFileName ? true : false
  })
  return (
    <div className='space-y-4'>
      <h1>Upload videos to this folder</h1>
      <div className='bg-slate-500 mt-4 min-h-[300px] flex items-center justify-center cursor-pointer' {...getRootProps()}>
        <input {...getInputProps()} />
        <div className='text-white'>
          {
            isDragActive
              ? videoFileName ? <p>Dropzone disabled</p> : <p>Drop the files here ...</p>
              : videoFileName ? <p>Dropzone disabled</p> : <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>
      </div>
      {videoFileName &&
        <div className='border border-black rounded-md p-2 flex justify-between'>
          <h2>{videoFileName}</h2>
          <button onClick={() => setVideoFileName('')}>Delete</button>
        </div>}
      {/* Buttons */}
      <div className='flex md:justify-end space-x-3'>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => { setVideoFile(null); setVideoFileName('') }}
          disabled={isEventOngoing}
        >
          Reset
        </button>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={uploadVideo}
          disabled={isEventOngoing}
        >
          {isEventOngoing ? 'Uploading...' : 'Upload'}
        </button>
      </div>
    </div>
  )
}

export default VideoUploader