import { XMarkIcon } from '@heroicons/react/24/solid'
import { Line } from 'rc-progress'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client'
import uuid from 'uuid-random'
import { axiosPrivate } from '../../api/axios'

export default function DocumentUploaderDirect({
  setFileUploader,
  currentFolderId,
  currentPathForS3,
  slug,
  currentOptimizationSocketId,
}) {
  const [files, setFiles] = useState([])
  const queryClient = useQueryClient()
  const [isEventOngoing, SetIsEventOngoing] = useState(false)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [processingFileName, setProcessingFileName] = useState('')
  const [currentSocketId, setCurrentSocketId] = useState('')

  const url = process.env.REACT_APP_BASE_URL?.toString()
  const socket = io(url, {
    autoConnect: false,
  })
  useEffect(() => {
    socket.connect()
    socket.on('connect', () => {
      setCurrentSocketId(socket.id)
      socket.emit('join_room', { room: socket.id })
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  //catch the upload event
  socket.on('upload', (message) => {
    setProcessingFileName(message.message)
  })

  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
  }, [])

  const handleUpload = async () => {
    try {
      //Set event ongoing status
      SetIsEventOngoing(true)
      if (files.length === 0) {
        toast.error('Please select a file')
        return
      }
      const batchSize = 2 // Number of images to upload in each batch
      const totalFiles = files.length
      const randomId = uuid()

      for (let i = 0; i < totalFiles; i += batchSize) {
        //using react query update the state using query key
        const batchFiles = files.slice(i, i + batchSize)
        const formData = new FormData()

        batchFiles.forEach((file) => {
          formData.append('files', file)
        })

        formData.append('randomId', randomId)
        formData.append('folderId', currentFolderId)
        formData.append('path', currentPathForS3)
        formData.append('roomName', currentSocketId)
        formData.append('optimizationRoomName', currentOptimizationSocketId)

        if (currentFolderId) {
          let successful = false

          while (!successful) {
            const fileUploadResponse = await axiosPrivate.post(
              '/upload',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              },
            )

            if (fileUploadResponse.status === 200) {
              successful = true
              // Handle successful response if needed
              // Toast message updating user of successful upload of part of the batch
              // toast.success(`Uploaded ${batchFiles.length} files`);
              const currentProgress = i + batchSize
              const overallPercentage = Math.round(
                (currentProgress / totalFiles) * 100,
              )
              setProgressPercentage(overallPercentage)
            } else {
              // Handle failed response or retry logic if needed
              //Toast message updating user of failed upload of part of the batch
              successful = false
              SetIsEventOngoing(false)
              setFiles([])
              toast.error(`Failed to upload ${batchFiles.length} files`)
              queryClient.invalidateQueries({
                queryKey: ['getImagesBasedOnFolderId'],
              })
              queryClient.invalidateQueries({
                queryKey: ['getCurrentFolderDetails'],
              })
              return
            }
          }
        }
      }

      // All batches have been uploaded
      setFiles([])
      SetIsEventOngoing(false)
      queryClient.invalidateQueries({ queryKey: ['getImagesBasedOnFolderId'] })
      queryClient.invalidateQueries({ queryKey: ['getCurrentFolderDetails'] })
      setProgressPercentage(0)
      setFileUploader(false)
      toast.success('All files uploaded successfully')
    } catch (error) {
      console.log('Error document uploader direct', error)
      toast.error('Something went wrong, please try again later')
    } finally {
      SetIsEventOngoing(false)
      // setFiles([]);
      setProgressPercentage(0)
      // setFileUploader(false)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg'],
    },
  })
  const thumbs = files.map((file, index) => (
    <div
      className='flex space-x-2 border border-gray-900 p-2 rounded-md'
      key={`file.name-${index}`}
    >
      <div className='flex-1'>
        <p>{file.name}</p>
      </div>
      <button
        type='button'
        className='rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500'
        onClick={() => {
          const newFiles = [...files]
          newFiles.splice(index, 1)
          setFiles(newFiles)
        }}
      >
        <XMarkIcon className='h-4 w-4' aria-hidden='true' />
      </button>
    </div>
  ))

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  return (
    <>
      <h1>Upload Files to this folder</h1>
      {/* cursor hand on a div */}
      <div
        className='bg-slate-500 mt-4 min-h-[300px] flex items-center justify-center cursor-pointer'
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className='text-white'>
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
      </div>
      <div className='flex-1'>
        <aside className='grid grid-cols-3 gap-4 my-4'>{thumbs}</aside>
      </div>
      {/* Action buttons */}
      <div className='flex-shrink-0 border-t border-gray-200  py-5 mt-auto'>
        <div className='flex justify-between flex-col md:flex-row space-x-4 items-center'>
          {/* Status */}
          <div className='flex bg-gray-900 flex-1 rounded-md text-white p-4 items-center'>
            <div className='flex flex-col flex-1'>
              <p>Total Files: {files.length}</p>
              {/* <p>Processing file number: {currentBatch.start} - {currentBatch.end}</p> */}
              <p className='text-orange-400'>
                Processing file name: {processingFileName}{' '}
              </p>
              <div className='max-w-lg flex space-x-4 items-center'>
                <Line
                  percent={progressPercentage}
                  strokeWidth={1}
                  strokeColor='#008000'
                />
                <div className='min-w-max'>
                  <p>{progressPercentage} / 100 %</p>
                </div>
              </div>
            </div>
            {isEventOngoing ? (
              <div
                className='flex h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]'
                role='status'
              >
                <span className='!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]'>
                  Loading...
                </span>
              </div>
            ) : null}
          </div>
          {/* Buttons */}
          <div className='flex md:justify-end space-x-3'>
            <button
              type='button'
              className='inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600 disabled:opacity-50 disabled:cursor-not-allowed'
              onClick={() => {
                setFiles([])
              }}
              disabled={isEventOngoing}
            >
              Reset
            </button>
            <button
              type='button'
              className='inline-flex items-center justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600 disabled:opacity-50 disabled:cursor-not-allowed'
              onClick={handleUpload}
              disabled={isEventOngoing}
            >
              Upload
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
