import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from '../api/axios';
// import logo from '../Logo/logopurple.svg'
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import ErrorAlert from './Helpers/ErrorAlert';
import SuccessAlert from './Helpers/SuccessAlert';
import { forgetPasswordSchema } from './Schemas/forgetPasswordSchema';

function ForgotPassword() {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(forgetPasswordSchema)
  });
  const [noUserError, setNoUserError] = useState(false)
  const forgotPasswordMutateFn = async (data) => {
    const { email, password, code, confirmPassword } = data
    return await axios.post('/forgot_password', { email, password, code, confirmPassword })
  }

  const mutation = useMutation(forgotPasswordMutateFn, {
    onSuccess: (data) => {
      setNoUserError(false)

    },
    onError: (err) => {
      if (err.response.status === 401) {
        setNoUserError(true)
      }
    }
  })

  const onSubmit = async (data) => {
    try {
      mutation.mutate(data)
    } catch (error) {
      console.error('error:', error.response)
    }
  }
  return (
    <>
      <div className="min-h-full bg-slate-100 w-[30rem] flex flex-col justify-center m-auto py-4 sm:px-4 lg:px-4">
        <div className="overflow-hidden space-y-6">
          <h2 className="mt-6 text-center text-xl font-semibold text-gray-900">Reset password</h2>
          <form className="space-y-6 block w-full min-w-max" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  {...register("email")}
                  className="text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                />
              </div>
              {errors.email && (
                <p className="mt-2 text-sm text-red-500">{errors.email.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="code" className="block text-sm font-medium text-slate-800">
                Secret Code
              </label>
              <div className="mt-1">
                <input
                  id="code"
                  name="code"
                  type="code"
                  autoComplete="code"
                  {...register("code")}
                  className="text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                />
              </div>
              {errors.code && (
                <p className="mt-2 text-sm text-red-500">{errors.code.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-slate-800">
                New Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="password"
                  {...register("password")}
                  className="text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                />
              </div>
              {errors.password && (
                <p className="mt-2 text-sm text-red-500">{errors.password.message}</p>
              )}
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-slate-800">
                Confirm new Password
              </label>
              <div className="mt-1">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="confirmPassword"
                  {...register("confirmPassword")}
                  className="text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-slate-500 focus:border-slate-500 sm:text-sm"
                />
              </div>
              {errors.confirmPassword && (
                <p className="mt-2 text-sm text-red-500">{errors.confirmPassword.message}</p>
              )}
            </div>
            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-800 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
              >
                {mutation.isLoading ? <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg> : 'Reset'}
              </button>
            </div>
          </form>
          <div className='flex flex-col space-y-4'>
            {mutation.isError && !noUserError && <ErrorAlert message="There was an error while sending the password reset email." />}
            {noUserError && <ErrorAlert message="No user with this email found." />}
            {mutation.isSuccess && <SuccessAlert message="password reset successfully!" />}
            {mutation.isSuccess && <Link className='mt-4 text-slate-900 flex items-center space-x-2' to="/">
              <span>Navigate to Portal</span>
              <ArrowRightIcon className="h-4 w-4" />
            </Link>}

          </div>
        </div>
      </div>
    </>
  )
}

export default ForgotPassword