import * as yup from 'yup'

export const sharableLinkSchema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
  sharableLink: yup.string().required('sharable link is required'),
  youtubeLink: yup.string().notRequired(),
  RMSLink: yup.string().notRequired(),
  cc: yup.string().notRequired(),
  message: yup.string().notRequired(),
})
