import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { axiosPrivate } from '../../api/axios'
import Breadcrumbs from '../Helpers/Breadcrumbs'
import ErrorAlert from '../Helpers/ErrorAlert'
import Folder from '../Helpers/Folder'
import SecondaryBtn from '../Helpers/SecondaryBtn'
import CreateClientFolder from './CreateClientFolder'

function IndividualClient() {
  const [slideStatus, setSlideStatus] = React.useState(false)
  const params = useParams()
  async function getSingleClient() {
    const { data } = await axiosPrivate.get(`/clients/${params.clientId}`)
    return data
  }

  const { data, isError, isLoading, isSuccess } = useQuery('getClient', getSingleClient)

  const clientData = data
  const rootFolder = clientData?.rootFolder

  async function getClientFolder(rootFolder) {
    if (!rootFolder) return
    const { data } = await axiosPrivate.get(`/folders/${rootFolder}`)
    return data
  }

  const { data: clientFolderData, isError: isErrorClientFolder, isLoading: isLoadingClientFolder, isSuccess: isSuccessClientFolder } = useQuery({
    queryKey: ['getClientFolder', rootFolder],
    queryFn: () => getClientFolder(rootFolder),
    enabled: !!rootFolder
  })

  function OpenCreateClientFolderSlide() {
    setSlideStatus(true)
  }

  const pages = [
    { name: `${clientData?.name}`, href: `/portal/clients/${clientData?._id}`, current: false },
  ]

  return (
    // PATH: clients/:clientId
    <div className="flex-1 flex flex-col items-stretch overflow-hidden p-8 space-y-6">
      <section aria-labelledby="primary-section" className="flex flex-col">
        <div className="pb-5 border-b flex flex-col space-y-4 sm:flex-row sm:space-y-0 justify-between">
          <div className='flex flex-col space-y-2'>
            {isLoading && <h3 className="text-lg leading-6 font-medium text-gray-900">Getting the client details...</h3>}
            {isError && <ErrorAlert message="Error getting a client details!" />}
            {isSuccess && clientData.name && <div>
              <h3 className="text-base md:text-lg text-gray-50">
                <span className='text-orange-200'>Client Name: </span>{clientData.name}</h3>
              <h3 className="text-base md:text-lg text-gray-50">
                <span className='text-orange-200'>Client Email: </span>{clientData.email}
              </h3>
            </div>}
          </div>
          {/* Slide over for creating a client */}
          <SecondaryBtn btnText="+ Create Folder" onClick={OpenCreateClientFolderSlide} />
        </div>
        <CreateClientFolder slideStatus={slideStatus} setSlideStatus={setSlideStatus} parentFolder={clientData?.rootFolder} isParentRoot={true} />
        <div className='space-y-4 mt-4'>
          {isLoading && <p>Loading...</p>}
          {isError && <ErrorAlert message="There is an error ocurred while getting the client and it's folders." />}
        </div>
      </section>
      {(isSuccess && clientData) ? <Breadcrumbs pages={pages} /> : <p>Loading the breadcrumb...</p>}
      <div>
        {isLoadingClientFolder && <p>Loading Client folders...</p>}
        {isErrorClientFolder && <ErrorAlert message="Error getting a client folders!" />}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {(isSuccessClientFolder && clientFolderData.length) ?
            (clientFolderData.map((folder) => (
              <span key={folder._id}>
                <Folder folder={folder} />
              </span>
            ))
            ) :
            <p>No Folders available.</p>}
        </div>
      </div>
    </div >
  )
}

export default IndividualClient